import React from 'react';
import { Link } from 'react-router-dom';

const SidebarLeft = () => {
  return (
    <aside id="sidebar-left" className="sidebar-left">
      <div className="sidebar-header">
        <div className="sidebar-title">
          Navigation
        </div>
        <div
          className="sidebar-toggle hidden-xs"
          data-toggle-class="sidebar-left-collapsed"
          data-target="html"
          data-fire-event="sidebar-left-toggle"
        >
          <i className="fa fa-bars" aria-label="Toggle sidebar"></i>
        </div>
      </div>

      <div className="nano">
        <div className="nano-content">
          <nav id="menu" className="nav-main" role="navigation">
            <ul className="nav nav-main">
              <li className="nav-active">
                <Link to="/dashboard">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/contacts">
                  <span className="pull-right label label-primary"></span>
                  <i className="fa fa-users" aria-hidden="true"></i>
                  <span>Contacts</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/newsletter">
                  <span className="pull-right label label-primary"></span>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span>Newsletter</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/campaigns">
                  <span className="pull-right label label-primary"></span>
                  <i className="fa fa-copy" aria-hidden="true"></i>
                  <span>Campaigns</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
}

export default SidebarLeft;
