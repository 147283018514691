import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContactService from '../../services/ContactService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faEnvelope, faStickyNote } from '@fortawesome/free-solid-svg-icons';

const statusOptions = [
  'NEW', 'CONTACTED', 'INTERESTED', 'FOLLOW_UP', 'AWAITING_RESPONSE',
  'QUALIFIED', 'NOT_QUALIFIED', 'NEGOTIATION', 'WON', 'LOST', 'LONG_TERM_FOLLOW_UP'
];

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    name: '',
    country: '',
    pageId: '',
    status: '',
    date: ''
  });

  // Estado para manejar la modal y su contenido
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(''); // Puede ser mensaje o nota
  const [isEditingNote, setIsEditingNote] = useState(false); // Para saber si estamos editando una nota
  const [selectedContactId, setSelectedContactId] = useState(null); // Guardar el id del contacto seleccionado
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [formError, setFormError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newContact, setnewContact] = useState({
    contactName: '',
    email: '',
    locale: navigator.language,
    phone: '',
    message: 'added manually',
    pageId: 3,
    selectedTypeOfInvestment: [],
    selectedTicket: [],
  });

  const fetchData = async (page) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        const data = await ContactService.getContactData(authToken, page, pageSize, filters);
        setContacts(data.content);
        setTotalPages(data.totalPages);
      } else {
        setError('No auth token found');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, filters]);

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
      setIsLoading(true);
    }
  };

  const handleStatusChange = async (contactId, newStatus) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        await ContactService.updateContactStatus(authToken, contactId, newStatus);
        fetchData(currentPage);
      } else {
        setError('No auth token found');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedContacts = [...contacts].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />;
    }
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const openModal = (content, isNote = false, contactId = null) => {
    setModalContent(content);
    setIsEditingNote(isNote);
    setSelectedContactId(contactId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
    setIsEditingNote(false);
    setSelectedContactId(null);
  };

  const handleSaveNote = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken && selectedContactId) {
        await ContactService.updateContactNotes(authToken, selectedContactId, modalContent);
        fetchData(currentPage);
        closeModal();
      } else {
        setError('No auth token found or no contact selected');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCreateContact = async () => {
    setFormError(''); // Clear any previous form errors

    if (!newContact.contactName.trim()) {
      setFormError('Name is required');
      return;
    }

    if (!newContact.email.trim()) {
      setFormError('Email is required');
      return;
    }

    if (newContact.selectedTypeOfInvestment.length === 0) {
      setFormError('Select one or more options in Investment Type');
      return;
    }

    if (newContact.selectedTicket.length === 0) {
      setFormError('Select one or more options in Investment Ticket');
      return;
    }

    setIsSubmitting(true);  // Activa el spinner
    try {

      await ContactService.sendContactRequest(newContact);
      fetchData(currentPage);
      setShowCreateForm(false);
      setSuccessMessage('Contact created successfully!');

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);  // Desactiva el spinner
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setnewContact((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setnewContact((prevFormData) => ({
        ...prevFormData,
        selectedTypeOfInvestment: [...prevFormData.selectedTypeOfInvestment, name],
      }));
    } else {
      setnewContact((prevFormData) => ({
        ...prevFormData,
        selectedTypeOfInvestment: prevFormData.selectedTypeOfInvestment.filter((option) => option !== name),
      }));
    }
  };

  const handleTicketChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setnewContact((prevFormData) => ({
        ...prevFormData,
        selectedTicket: [...prevFormData.selectedTicket, name],
      }));
    } else {
      setnewContact((prevFormData) => ({
        ...prevFormData,
        selectedTicket: prevFormData.selectedTicket.filter((ticket) => ticket !== name),
      }));
    }
  };

  // Función para formatear la fecha en dd/mm/yyyy
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes empieza desde 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <section className="body">
      <header className="page-header">
        <h2>Contacts</h2>
        <div className="right-wrapper pull-right">
          <ol className="breadcrumbs">
            <li>
              <Link to="/dashboard">
                <i className="fa fa-users"></i>
              </Link>
            </li>
            <li><span>Contacts</span></li>
          </ol>
          <a className="sidebar-right-toggle" data-open="sidebar-right">
            <i className="fa fa-chevron-left"></i>
          </a>
        </div>
      </header>

      {/* start: page */}
      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Contact List</h2>
          <div className="panel-body">
            <div className="filter-form row">
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  value={filters.name}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  name="country"
                  placeholder="Country"
                  value={filters.country}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <select
                  name="pageId"
                  className="form-control"
                  value={filters.pageId}
                  onChange={handleFilterChange}
                >
                  <option value="">All Pages</option>
                  <option value="1">Investments</option>
                  <option value="2">Products</option>
                  <option value="3">Added Manually</option>
                </select>
              </div>
              <div className="col-md-2">
                <select
                  name="status"
                  className="form-control"
                  value={filters.status}
                  onChange={handleFilterChange}
                >
                  <option value="">All Status</option>
                  {statusOptions.map(status => (
                    <option key={status} value={status}>
                      {status.replace(/_/g, ' ').toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={filters.date}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <button className="btn btn-primary" onClick={() => setShowCreateForm(true)}>Create Contact</button>
              </div>
            </div>
          </div>
        </header>
        <div className="panel-body">
        <div className="table-responsive">
          <table className="table table-bordered table-striped mb-none">
            <thead>
              <tr>
                <th onClick={() => handleSort('contactName')} style={{ whiteSpace: 'nowrap' }}>
                  Name {getSortIcon('contactName')}
                </th>
                <th onClick={() => handleSort('email')} style={{ whiteSpace: 'nowrap' }}>
                  Email {getSortIcon('email')}
                </th>
                <th onClick={() => handleSort('locale')} style={{ whiteSpace: 'nowrap' }}>
                  Country {getSortIcon('locale')}
                </th>
                <th onClick={() => handleSort('selectedTypeOfInvestment')} style={{ whiteSpace: 'nowrap' }}>
                Type Of Investment {getSortIcon('selectedTypeOfInvestment')}
                </th>
                <th onClick={() => handleSort('selectedTicket')} style={{ whiteSpace: 'nowrap' }}>
                Type Of Investment {getSortIcon('selectedTicket')}
                </th>
                <th onClick={() => handleSort('message')} style={{ whiteSpace: 'nowrap' }}>
                  Message {getSortIcon('message')}
                </th>
                <th onClick={() => handleSort('notes')} style={{ whiteSpace: 'nowrap' }}>
                  Notes {getSortIcon('notes')}
                </th>
                <th onClick={() => handleSort('pageId')} style={{ whiteSpace: 'nowrap' }}>
                  Page {getSortIcon('pageId')}
                </th>
                <th onClick={() => handleSort('status')} style={{ whiteSpace: 'nowrap' }}>
                  Status {getSortIcon('status')}
                </th>
                <th onClick={() => handleSort('createdAt')} style={{ whiteSpace: 'nowrap' }}>
                  Date {getSortIcon('createdAt')}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedContacts.map(contact => (
                <tr key={contact.id}>
                  <td>{contact.contactName}</td>
                  <td>{contact.email}</td>
                  <td>{contact.locale}</td>
                  <td>{contact.selectedTypeOfInvestment}</td>
                  <td>{contact.selectedTicket}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ cursor: 'pointer' }}
                      onClick={() => openModal(contact.message)}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faStickyNote}
                      style={{ cursor: 'pointer' }}
                      onClick={() => openModal(contact.notes, true, contact.id)}
                    />
                  </td>
                  <td>
                    {(() => {
                      switch (contact.pageId) {
                        case 1:
                          return 'Investments';
                        case 2:
                          return 'Products';
                        case 3:
                          return 'Added Manually';
                      }
                    })()}
                  </td>
                  <td>
                    <select
                      value={contact.contactStatusEnum}
                      onChange={(e) => handleStatusChange(contact.id, e.target.value)}
                    >
                      {statusOptions.map(status => (
                        <option key={status} value={status}>
                          <b>{status.replace(/_/g, ' ').toUpperCase()}</b>
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>{formatDate(contact.registrationDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </section>
      {/* end: page */}

      {/* Paginación */}
      <div className="pagination-container">
        <ul className="pagination">
          <li className={currentPage === 0 ? 'disabled' : ''}>
            <a onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} className={index === currentPage ? 'active' : ''}>
              <a onClick={() => handlePageChange(index)}>{index + 1}</a>
            </li>
          ))}
          <li className={currentPage === totalPages - 1 ? 'disabled' : ''}>
            <a onClick={() => handlePageChange(currentPage + 1)}>Next</a>
          </li>
        </ul>
      </div>

      {/* Modal para mostrar mensaje o editar nota */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h4>{isEditingNote ? 'Edit Note' : 'Message'}</h4>
              <button onClick={closeModal} className="close-button">×</button>
            </div>
            <div className="modal-body">
              {isEditingNote ? (
                <textarea
                  value={modalContent}
                  onChange={(e) => setModalContent(e.target.value)}
                  style={{ width: '100%', height: '150px' }}
                />
              ) : (
                <p>{modalContent}</p>
              )}
            </div>
            {isEditingNote && (
              <div className="modal-footer">
                <button onClick={handleSaveNote} className="btn btn-primary">Save</button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Modal for creating a contact */}
      {showCreateForm && (
        <div className="modal-overlay" onClick={() => setShowCreateForm(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Create New Contact</h3>
              <button className="close-button" onClick={() => setShowCreateForm(false)}>X</button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <span className="error-message">{formError}</span> {/* Display form error */}
                <div className="form-group">
                  <input
                    type="text"
                    name="contactName"
                    className="form-control"
                    id="name"
                    value={newContact.contactName}
                    onChange={handleInputChange}
                    placeholder="Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={newContact.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Phone"
                    value={newContact.phone}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Type of Investment:</label>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Profitability"
                        value="Profitability"
                        onChange={handleCheckboxChange}
                      />{' '}
                      Profitability
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        name="Flipping"
                        value="Flipping"
                        onChange={handleCheckboxChange}
                      />{' '}
                      Flipping
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        name="Purchase on credit"
                        value="Purchase on credit"
                        onChange={handleCheckboxChange}
                      />{' '}
                      Purchase on credit
                    </label>
                  </div>
                </div>
                {/* Segundo conjunto de checkbox */}
                <div className="form-group">
                  <label>Investment Ticket:</label>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="50k to 250k"
                        value="50k to 250k"
                        onChange={handleTicketChange}
                      />{' '}
                      50k to 250k
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        name="250k to 500k"
                        value="250k to 500k"
                        onChange={handleTicketChange}
                      />{' '}
                      250k to 500k
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        name="More than 500k"
                        value="More than 500k"
                        onChange={handleTicketChange}
                      />{' '}
                      More than 500k
                    </label>
                  </div>
                </div>
                <div className="form-group text-right">
                  <button type="button" className="btn btn-primary" onClick={handleCreateContact} disabled={isSubmitting}>
                    {isSubmitting ? <div className="spinner"></div> : 'Create'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </section>


  );
};

export default Contacts;
