import React from 'react';

const DashboardSummaries = ({ data }) => {
  return (
    <div className="row">
      <div className="col-md-6 col-lg-12 col-xl-6">
        <div className="row">
          <div className="col-md-12 col-lg-6 col-xl-6">
            <section className="panel panel-featured-left panel-featured-primary">
              <div className="panel-body">
                <div className="widget-summary">
                  <div className="widget-summary-col widget-summary-col-icon">
                    <div className="summary-icon bg-primary">
                      <i className="fa fa-home"></i>
                    </div>
                  </div>
                  <div className="widget-summary-col">
                    <div className="summary">
                      <h4 className="title">Product page contacts</h4>
                      <div className="info">
                        <strong className="amount">{data.countContactProductsPage}</strong>
                        <span className="text-primary">({data.countNewsContactProductsPage} new)</span>
                      </div>
                    </div>
                    <div className="summary-footer">
                      <a className="text-muted text-uppercase" href="#">(view all)</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="col-md-12 col-lg-6 col-xl-6">
            <section className="panel panel-featured-left panel-featured-secondary">
              <div className="panel-body">
                <div className="widget-summary">
                  <div className="widget-summary-col widget-summary-col-icon">
                    <div className="summary-icon bg-secondary">
                      <i className="fa fa-dollar"></i>
                    </div>
                  </div>
                  <div className="widget-summary-col">
                    <div className="summary">
                      <h4 className="title">Investment page contacts</h4>
                      <div className="info">
                        <strong className="amount">{data.countContactInvestmentsPage}</strong>
                        <span className="text-primary">({data.countNewContactInvestmentsPage} new)</span>
                      </div>
                    </div>
                    <div className="summary-footer">
                      <a className="text-muted text-uppercase" href="#">(withdraw)</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="col-md-12 col-lg-6 col-xl-6">
            <section className="panel panel-featured-left panel-featured-tertiary">
              <div className="panel-body">
                <div className="widget-summary">
                  <div className="widget-summary-col widget-summary-col-icon">
                    <div className="summary-icon bg-tertiary">
                      <i className="fa fa-envelope"></i>
                    </div>
                  </div>
                  <div className="widget-summary-col">
                    <div className="summary">
                      <h4 className="title">Total newletter registrations</h4>
                      <div className="info">
                        <strong className="amount">{data.countNewsletterPage}</strong>
                      </div>
                    </div>
                    <div className="summary-footer">
                      <a className="text-muted text-uppercase" href="#">(statement)</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="col-md-12 col-lg-6 col-xl-6">
            <section className="panel panel-featured-left panel-featured-quartenary">
              <div className="panel-body">
                <div className="widget-summary">
                  <div className="widget-summary-col widget-summary-col-icon">
                    <div className="summary-icon bg-quartenary">
                      <i className="fa fa-users"></i>
                    </div>
                  </div>
                  <div className="widget-summary-col">
                    <div className="summary">
                      <h4 className="title">Total visitors to the pages (last 30 days)</h4>
                      <div className="info">
                        <strong className="amount"><span>Investments: </span>{data.countInvestmentsPageVisitorsLast30Days}</strong>
                        <span className="text-primary">({data.countInvestmentsPageVisitorsLast24Hours} last 24 hours)</span>
                        <br/>
                        <strong className="amount"><span>Products: </span>{data.countProductsPageVisitorsLast30Days}</strong>
                        <span className="text-primary">({data.countProductsPageVisitorsLast24Hours} last 24 hours)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSummaries;
