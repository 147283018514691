import React from 'react';
import { MemoryRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardService from './services/DashboardService';
import Header from './components/header/Header';
import SidebarLeft from './components/sidebarLeft/SidebarLeft';
import PageHeader from './components/pageHeader/PageHeader';
import DashboardSummaries from './components/dashboard/DashboardSummaries';
import Login from './components/login/Login';
import Contacts from './components/contacts/Contacts';
import Newsletter from './components/newsletter/Newsletter';
import Campaigns from './components/campaigns/Campaigns';


// Verifica si el usuario está autenticado
const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');
};

// Ruta privada para proteger las rutas
const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/" />;
};

// Componente para el Dashboard con enrutamiento interno
const Dashboard = () => {
  const [dashboardData, setDashboardData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
          const result = await DashboardService.getDashboardData(authToken);
          setDashboardData(result);
        } else {
          setError('No auth token found');
        }
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
        setError('Failed to fetch dashboard data');
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated()) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <section className="body">
      <Header data={dashboardData} />
      <div className="inner-wrapper">
        <SidebarLeft />
        <section role="main" className="content-body">
          <PageHeader data={dashboardData} />
          <Routes>
            <Route path="summaries" element={<DashboardSummaries data={dashboardData} />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="newsletter" element={<Newsletter />} />
            <Route path="campaigns" element={<Campaigns />} />
            <Route path="*" element={<DashboardSummaries data={dashboardData} />} />
          </Routes>
        </section>
      </div>
    </section>
  );
};

// Componente principal de la aplicación
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<PrivateRoute element={<Dashboard />} />} />
      </Routes>
    </Router>
  );
}

export default App;
